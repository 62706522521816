<template>
    <AtualizarFluxo />
</template>

<script>
import AtualizarFluxo from '@/components/Fluxo/AtualizarFluxo'
export default {
  components: {AtualizarFluxo},
  data(){
    return{}
  }
}
</script>

<style>

</style>